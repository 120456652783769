define(['app'], function(app) {

  const backToTopButton = () => {
    const component = {};
    component.app = app;

    const _config = {
      backToTopStickyClass: 'backToTopButton-sticky',
      backToTopVisibleClass: 'backToTopButton-visible',
      backToTopDisabledClass: 'backToTopButton-disabled',
      backToTopEnabledClass: 'backToTopButton-enabled',
      backToTopOffset: 500
    };

    const _init = element => {
      component.element = element;
      component.pageYOffset = window.pageYOffset;
      component.attachListeners();
      return component;
    };

    const _attachListeners = () => {
      window.addEventListener('scroll', component.showBackToTopButton);
      component.element.addEventListener('click', component.scrollBackToTop);
    };

    const _showBackToTopButton = () => {
      app.element.hasClass(component.config.backToTopStickyClass, component.element) ?
        component.pageYOffset > component.config.backToTopOffset ?
          component.addVisibleClass() :
          component.removeVisibleClass() :
        component.pageYOffset > component.config.backToTopOffset ?
          component.addEnabledClass() :
          component.addDisabledClass();

      return (component.pageYOffset = window.pageYOffset);
    };

    const _scrollBackToTop = event => {
      event.preventDefault();
      app.element.scrollTo(1, component.config.backToTopOffset);
    };

    const _addVisibleClass = () => {
      app.element.addClass(component.config.backToTopVisibleClass, component.element);
    };

    const _removeVisibleClass = () => {
      app.element.removeClass(component.config.backToTopVisibleClass, component.element);
    };

    const _addEnabledClass = () => {
      app.element.removeClass(component.config.backToTopDisabledClass, component.element);
      app.element.addClass(component.config.backToTopEnabledClass, component.element);
    };

    const _addDisabledClass = () => {
      app.element.removeClass(component.config.backToTopEnabledClass, component.element);
      app.element.addClass(component.config.backToTopDisabledClass, component.element);
    };

    component.config = _config;
    component.init = _init;
    component.attachListeners = _attachListeners;
    component.showBackToTopButton = _showBackToTopButton;
    component.scrollBackToTop = _scrollBackToTop;
    component.addVisibleClass = _addVisibleClass;
    component.removeVisibleClass = _removeVisibleClass;
    component.addEnabledClass = _addEnabledClass;
    component.addDisabledClass = _addDisabledClass;

    return component;
  };

  return backToTopButton;
});
